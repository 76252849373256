import tw from 'twin.macro';
import React from 'react';
import { Link } from 'gatsby';

import Collapse from '../elements/Collapse';

const Sidebar = ({
  onPriceChange,
  handleJetsChange,
  handleSeatsChange,
  selectedPriceRange,
  noOfJets,
  noOfSeats,
  deal,
  isActiveCondition,
}) => {
  const { conditionFilter } = deal;
  // console.log(isActiveCondition, 'isActiveCondition');
  return (
    <div>
      <p tw="py-10 font-bold">Filters</p>
      {conditionFilter?.length > 0 && (
        <Collapse title="Condition" initialValue={isActiveCondition}>
          <Link to="/deals">
            <p tw="py-1">All</p>
          </Link>
          {conditionFilter &&
            conditionFilter.map((item) => {
              if (!item?.slug?.current) return null;
              return (
                <div
                  key={item._id}
                  tw="mt-3 font-medium hover:underline hover:text-green"
                >
                  <Link to={`/condition/${item.slug && item.slug.current}`}>
                    {item.title}
                  </Link>
                </div>
              );
            })}
        </Collapse>
      )}
      {deal?.seatsRange?.length > 0 && (
        <Collapse title="Number of seats">
          <a
            onClick={() => {
              handleSeatsChange('');
            }}
          >
            <p css={[tw`py-1 `, !noOfSeats && tw`font-bold`]}>All</p>
          </a>
          {deal?.seatsRange.map((item) => {
            if (!item) return null;
            return (
              <a
                css={[
                  tw`hover:underline cursor-pointer`,
                  noOfSeats &&
                  noOfSeats.fromValue === item.toValue &&
                  noOfSeats.toValue === item.toValue
                    ? tw`font-bold hover:underline`
                    : tw``,
                ]}
                key={item._key}
                onClick={() =>
                  handleSeatsChange({
                    from: item.fromValue,
                    to: item.toValue,
                  })
                }
              >
                <p tw="py-1">{`${
                  item.fromValue === 0 ? 'Under' : `From ${item.fromValue}`
                } - ${`to ${item.toValue}`}`}</p>
              </a>
            );
          })}
        </Collapse>
      )}
      {deal?.jetsRange.length > 0 && (
        <Collapse title="Number of jets">
          <a
            onClick={() => {
              handleJetsChange('');
            }}
          >
            <p css={[tw`py-1 `, !noOfJets && tw`font-bold`]}>All</p>
          </a>
          {deal?.jetsRange &&
            deal?.jetsRange.map((item) => {
              if (!item) return null;
              return (
                <a
                  key={item._key}
                  css={[
                    tw`cursor-pointer`,
                    noOfJets &&
                    noOfJets.fromValue === item.fromValue &&
                    noOfJets.toValue === item.toValue
                      ? tw`font-bold hover:underline`
                      : tw`hover:underline`,
                  ]}
                  onClick={() =>
                    handleJetsChange({
                      fromValue: item.fromValue,
                      toValue: item.toValue,
                    })
                  }
                >
                  <p tw="py-1">{`${
                    item.fromValue === 0 ? 'Under' : `From ${item.fromValue}`
                  } - ${`to ${item.toValue}`}`}</p>
                </a>
              );
            })}
        </Collapse>
      )}

      {deal?.priceRange.length > 0 && (
        <Collapse title="Price">
          {deal?.priceRange &&
            deal?.priceRange.map((item) => {
              if (!item) return null;
              return (
                <a
                  key={item._key}
                  css={[
                    tw`cursor-pointer`,
                    selectedPriceRange &&
                    selectedPriceRange.fromValue === item.fromValue &&
                    selectedPriceRange.toValue === item.toValue
                      ? tw`font-bold hover:underline`
                      : tw`hover:underline`,
                  ]}
                  onClick={() =>
                    onPriceChange({
                      fromValue: item.fromValue,
                      toValue: item.toValue,
                    })
                  }
                >
                  <p tw="py-1">{`${
                    item.fromValue === 0 ? 'Under' : `£${item.fromValue}`
                  } - ${`£${item.toValue}`}`}</p>
                </a>
              );
            })}
        </Collapse>
      )}
    </div>
  );
};

export default Sidebar;
