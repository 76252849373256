import 'twin.macro';

import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Seo from '../components/global/Seo';
import DealLayout from '../components/deals/DealLayout';
import config from '../utils/config';

const ConditionView = ({ data }) => {
  const { condition, products, sanityDeal } = data;
  if (!condition) return null;
  return (
    <Layout>
      <Seo
        title={`${condition.title} ${config.siteName}`}
        description={condition.description}
        // image={condition.image.asset.url}
      />

      <DealLayout
        data={products.nodes}
        deal={sanityDeal}
        isActiveCondition={condition?.slug?.current}
      />
    </Layout>
  );
};

ConditionView.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ConditionView;

export const query = graphql`
  query ($slug: String!) {
    condition: sanityCondition(slug: { current: { eq: $slug } }) {
      _id
      title
      slug {
        current
      }
    }
    products: allSanityProduct(
      filter: {
        condition: { slug: { current: { eq: $slug } } }
        productVisibility: { in: ["both", "deal"] }
      }
      sort: { fields: [order, _updatedAt], order: [ASC] }
    ) {
      nodes {
        id
        title
        _rawBody
        description
        numberOfJets
        numberOfSeats
        price
        slug {
          current
        }
        colors {
          title
          value
        }
        condition {
          _id
          title
          slug {
            current
          }
        }
        image {
          asset {
            gatsbyImageData
          }
        }
        brand {
          image {
            asset {
              gatsbyImageData
            }
          }
        }
      }
    }
    sanityDeal {
      conditionFilter {
        _id
        title
        slug {
          current
        }
      }
      priceRange {
        _key
        fromValue
        toValue
      }
      jetsRange {
        _key
        fromValue
        toValue
      }
      seatsRange {
        _key
        fromValue
        toValue
      }
    }
  }
`;
