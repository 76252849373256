import React from 'react';
import currency from 'currency.js';
import config from '../../utils/config';

const CurrencyFormat = ({ amount }) => {
  const formatCurrency = (val, currencySymbol) => {
    return currency(parseFloat(val), {
      symbol: `${currencySymbol || config.currency}`,
      formatWithSymbol: true,
    }).format();
  };

  return <>{formatCurrency(amount)}</>;
};

export default CurrencyFormat;
